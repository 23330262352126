import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Avatar, TextField, Typography, Autocomplete, Chip, Button, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { useDispatch, useSelector } from 'src/redux/store';
import axios, { webHookAxiosInstace } from 'src/utils/axios';
import { ApiEndpoints } from 'src/config';
import { useSnackbar } from 'notistack';
import { formatPhoneNumber, getInitials } from './ChatConversationItem';
import CustomConfirmBox from 'src/components/CustomConfirmBox';
import { getContacts, setActiveConversation } from 'src/redux/slices/chat';
import { BootstrapTooltip } from './ChatConversationList';
import DoneIcon from '@mui/icons-material/Done';

// ----------------------------------------------------------------------

;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1),
  boxShadow: `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)`,
}));

// ----------------------------------------------------------------------

ChatHeaderCompose.propTypes = {
  contacts: PropTypes.array,
  recipients: PropTypes.array,
  onAddRecipients: PropTypes.func,
};

export default function ChatHeaderCompose({
  contacts,
  recipients,
  onAddRecipients,
  activeConversationId,
  hoursRemaining,
  templates, 
  setTemplates
}) {
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  console.log(value,'templatevalue')

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.auth?.user?.data);
  const agentId = userData?.user?.native_id;
  const name = userData?.user?.name;

  const handleTemplateSelection = async()=>{
    let val ={
      customers:[formatPhoneNumber(activeConversationId?.customerPhone)?.replace(' ', '')],
      template_id: +value?.id
    }
    await axios
      .post(ApiEndpoints.WA_TEMPLATE_TRIGGER, val)
      .then((response) => {
        if(response?.data?.status===200){
          handleTemplateDialogClose()
          enqueueSnackbar('Template assigned Successfully.', { variant: 'success' })
        }
  }
  )}
  

  const handleTemplateDialogClose = () => {
    setOpenTemplateDialog(false);
    setValue(null); 
  };

  const handleTemplateDialogConfirm = () => {
    handleTemplateSelection()
  };

  const handDialogBox = () => {
    setOpenDialogBox(!openDialogBox);
  };
  const handleDialogBoxYes = () => {
    handleCloseChat();
  };
  const handleAddRecipients = (recipients) => {
    setQuery('');
    onAddRecipients(recipients);
  };
  const handleCloseChat = async () => {
    const payload = {
      agentId: agentId,
      userId: contacts?.[0]?.to,
    };
    await webHookAxiosInstace
      .post(ApiEndpoints.CLOSE_CHAT_BY_AGENT, payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.status === false) {
          handDialogBox();
          dispatch(getContacts(agentId));
          enqueueSnackbar('Closed Successfully', { variant: 'success' });
        } else {
          enqueueSnackbar('Error please Try again', { variant: 'error' });
        }
      })
      .catch((error) => {
        console.error('Api Call Error while closing chat:', error);
        enqueueSnackbar('Error please Try again after some time', { variant: 'error' });
      });
  };

  

  const top10Films = [{ label: 'Sachin Pandey' }];
  return (
    <>
      <CustomConfirmBox
        open={openDialogBox}
        handleClose={handDialogBox}
        title={`Are you sure you want to close the chat with ${contacts?.[0]?.to} ?`}
        handleCloseYes={handleDialogBoxYes}
      />
      <CustomConfirmBox
        open={openTemplateDialog}
        handleClose={handleTemplateDialogClose}
        title={`Confirm template selection: ${value?.name}?`}
        handleCloseYes={handleTemplateDialogConfirm}
      />
      <RootStyle>
        <div style={{ display: 'inline-block' }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', mr: 0.5 }}>
              {activeConversationId?.customerInfo?.customer_name
                ? activeConversationId?.customerInfo?.customer_name
                : formatPhoneNumber(activeConversationId?.customerPhone)}
            </Typography>
            <BootstrapTooltip title={contacts?.[0]?.to}>
              <IconButton sx={{ p: 0, minWidth: 'auto' }}>
                <Iconify icon="eva:info-outline" width={15} height={15} />
              </IconButton>
            </BootstrapTooltip>
          </Box>
          <svg
            stroke="currentColor"
            fill="#22c35e"
            stroke-width="0"
            viewBox="0 0 512 512"
            focusable="false"
            class="chakra-icon css-fhs5hy"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M260.062 32C138.605 32 40.134 129.701 40.134 250.232c0 41.23 11.532 79.79 31.559 112.687L32 480l121.764-38.682c31.508 17.285 67.745 27.146 106.298 27.146C381.535 468.464 480 370.749 480 250.232 480 129.701 381.535 32 260.062 32zm109.362 301.11c-5.174 12.827-28.574 24.533-38.899 25.072-10.314.547-10.608 7.994-66.84-16.434-56.225-24.434-90.052-83.844-92.719-87.67-2.669-3.812-21.78-31.047-20.749-58.455 1.038-27.413 16.047-40.346 21.404-45.725 5.351-5.387 11.486-6.352 15.232-6.413 4.428-.072 7.296-.132 10.573-.011 3.274.124 8.192-.685 12.45 10.639 4.256 11.323 14.443 39.153 15.746 41.989 1.302 2.839 2.108 6.126.102 9.771-2.012 3.653-3.042 5.935-5.961 9.083-2.935 3.148-6.174 7.042-8.792 9.449-2.92 2.665-5.97 5.572-2.9 11.269 3.068 5.693 13.653 24.356 29.779 39.736 20.725 19.771 38.598 26.329 44.098 29.317 5.515 3.004 8.806 2.67 12.226-.929 3.404-3.599 14.639-15.746 18.596-21.169 3.955-5.438 7.661-4.373 12.742-2.329 5.078 2.052 32.157 16.556 37.673 19.551 5.51 2.989 9.193 4.529 10.51 6.9 1.317 2.38.901 13.531-4.271 26.359z"></path>
          </svg>
        </div>
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {activeConversationId?.status && (
            <BootstrapTooltip title="Conversation will be resolved">
              <Button variant="outlined" startIcon={<DoneIcon />} sx={{ height: '25px' }} onClick={handDialogBox}>
                Resolve
              </Button>
            </BootstrapTooltip>
          )}
           <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={templates}
            getOptionLabel={(option) => option.name}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              if (newValue) {
                setQuery(newValue.name);
                setOpenTemplateDialog(true);
              }
            }}
            sx={{ width: '200px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Template"
                sx={{
                  borderRadius: '10px',
                  '& .MuiInputBase-input': {
                    fontSize: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '25px',
                    '& fieldset': {
                      borderRadius: '5px',
                    },
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} style={{
                wordBreak: 'break-word',
                whiteSpace: 'normal', 
              }}>
               {option?.name} 
              </li>
            )}
            noOptionsText={<SearchNotFound searchQuery={query} />}
          />
          <IconButton>
            <Iconify icon="eva:search-fill" width={20} height={20} />
          </IconButton>
          <IconButton>
            <Iconify icon="mdi:timer-off" width={20} height={20} />:
          </IconButton>
          {hoursRemaining + ' hrs remaining'}
          <IconButton>
            <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
          </IconButton>
        </Box>
      </RootStyle>
    </>
  );
}
