import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from '@mui/material';
import Image from '../../../components/Image';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReplyIcon from '@mui/icons-material/Reply';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import { InfoOutlined } from '@mui/icons-material';
import { Picker } from 'emoji-mart';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoneIcon from '@mui/icons-material/Done';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import TimerSharpIcon from '@mui/icons-material/TimerSharp';
import CollectionsIcon from '@mui/icons-material/Collections';
import DocIcon from '../../../assets/doc.png';
import { formatBytes, getMimeType } from './ChatMessageInput';

// Common styled components
const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 500,
  padding: theme.spacing(1),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  hyphens: 'auto',
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.75),
  color: theme.palette.text.secondary,
}));

const PopoverStyle = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    borderRadius: theme.shape.borderRadius,
    minWidth: 100,
  },
}));

const PopoverList = styled(List)(({ theme }) => ({
  padding: 0,
}));

const PopoverListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const PopoverIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 28,
  marginRight: theme.spacing(0.2), 
}));

const SmallIconButton = styled(IconButton)(({ theme }) => ({
  width: 20,
  height: 20,
  backgroundColor: theme.palette.grey[300],
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

export function formatTime(dateString) {
  const date = new Date(dateString + ' UTC');

  let hours = date?.getHours();
  const minutes = date?.getMinutes();

  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; 

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${formattedMinutes} ${ampm}`;
}


ChatMessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItem({ message, onOpenLightbox ,fileData,filePreview, uploadData }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMe = message?.messageType === 'OUTGOING' || message?.isAgent;
  const messageStatus = message?.status
  const isImage = message?.fileType === 'IMAGE';
  const isDocument = message?.fileType === 'DOCUMENT'
  const isTime = message?.status === 'sent' ? formatTime(message?.sentTime):messageStatus === 'read' ? formatTime(message?.readTime):messageStatus === 'delivered' ?formatTime(message?.deliveryTime):messageStatus === 'failed'?formatTime(message?.failedTime):'13:28 pm'

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderMessageContent = () => {
    if (isImage) {
      return (
        <>
        <Image
          alt="attachment"
          src={message?.fileUrl}
          onClick={() => onOpenLightbox(message.body)}
          sx={{ borderRadius: 1, p:1 }}
        />
         <Typography variant="body2" sx={{px:1,pb:1}}>
        {message?.text ||
          (typeof message?.message === 'string' && message?.message) ||
          message?.message?.data ||
          (typeof message?.message === 'object' && (message?.message?.message || message?.message?.inComing)) ||
          message?.message?.data?.[0]?.text}
      </Typography>
        </>
      );
    }

    if (isDocument) {
      const handleClick = (e) => {
        const fileUrl = message?.fileUrl; 
    
        if (getMimeType(message.mimeType) === 'PDF') {
          window.open(fileUrl, '_blank', 'noopener,noreferrer');
        } else {
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', message?.documentFileName || 'file'); 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
            e.preventDefault();
      };
    
      return (
        <>
        <Box
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: message?.messageType==='OUTGOING'?'#BEE4F4':'#C0C0C0',
            borderRadius: 1,
            gap: 0.5,
            p: 1,
            cursor: 'pointer',
            '&:hover': { opacity: 0.8 },
          }}
        >
          {getMimeType(message?.mimeType) === 'PDF' ? (
            <PictureAsPdfIcon sx={{ fontSize: 60 }} />
          ) : getMimeType(message?.mimeType) === 'DOCX' ? (
            <img src={DocIcon} alt="docicon" style={{ width: '50px', height: '50px' }} />
          ) : (
            <ArticleIcon sx={{ fontSize: 60 }} />
          )}
          <div>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {`${getMimeType(message?.mimeType)}-${message?.documentFileName}`}
            </Typography>
            <Typography variant="body2">{`* ${getMimeType(message?.mimeType)}`}</Typography>
          </div>
        </Box>
        <Typography variant="body2">
        {message?.text ||
          (typeof message?.message === 'string' && message?.message) ||
          message?.message?.data ||
          (typeof message?.message === 'object' && (message?.message?.message || message?.message?.inComing)) ||
          message?.message?.data?.[0]?.text}
      </Typography>
        </>
      );
    }
    
    

    if (Array.isArray(message?.message?.data) && message?.message?.data?.length > 0) {
      return message.message.data.map((messageItem, index) => (
        <div key={index}>
          {Array.isArray(messageItem?.text) ? (
            <>
              {messageItem.text
                .filter((item) => item.textMessage)
                .map((item, idx) => (
                  <Typography key={idx} variant="body2">
                    {item.textMessage}
                  </Typography>
                ))}
              {messageItem.text.filter((item) => item.dn && item.value).length > 0 && <Divider />}
              <ul style={{ padding: 0, margin: 0, display: 'flex', justifyContent: 'space-evenly' }}>
                {messageItem.text
                  .filter((item) => item.dn && item.value)
                  .map((item) => (
                    <li
                      key={item.dn}
                      style={{
                        padding: '2px',
                        listStyle: 'none',
                        marginTop: '2px',
                      }}
                    >
                      <Button variant="filled" sx={{ cursor: 'no-drop' }}>
                        {item.value}
                      </Button>
                    </li>
                  ))}
              </ul>
            </>
          ) : (
            <Typography variant="body2">{messageItem?.text?.body || messageItem?.text}</Typography>
          )}
        </div>
      ));
    }

    return (
      <Typography variant="body2">
        {message?.text ||
          (typeof message?.message === 'string' && message?.message) ||
          message?.message?.data ||
          (typeof message?.message === 'object' && (message?.message?.message || message?.message?.inComing)) ||
          message?.message?.data?.[0]?.text}
      </Typography>
    );
  };

  return (
    <>
      {message?.message?.message === 'Agent Assigned' ? (
        <Divider>
          <Chip label={message?.message?.message} size="small" />
        </Divider>
      ) : (
        <RootStyle>
          <Box
            sx={{
              display: 'flex',
              ...(isMe && {
                ml: 'auto',
              }),
            }}
          >
            <div>
              <InfoStyle
                variant="caption"
                sx={{
                  ...(isMe && { justifyContent: 'flex-end' }),
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  position: 'relative',
                  ...(!isMe && { flexDirection: 'row-reverse' }),
                  '&:hover .icon-container': {
                    opacity: 1, // Show the icon box on hover
                  },
                }}
              >
                <Box
                  className="icon-container"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    ...(!isMe && { flexDirection: 'row-reverse' }),
                    gap: 0.5,
                    padding: 0.5,
                    opacity: open ? 1 : 0, // Initially hidden if popover is not open
                    transition: 'opacity 0.3s ease', // Smooth transition for the hover effect
                  }}
                >
                  <SmallIconButton onClick={handlePopoverOpen}>
                    <KeyboardArrowDownIcon sx={{ width: 15, height: 15 }} />
                  </SmallIconButton>
                  <SmallIconButton>
                    <ReplyIcon sx={{ width: 15, height: 15 }} />
                  </SmallIconButton>
                </Box>

                <ContentStyle
                  sx={{
                    ...(isMe ? { color: 'grey.800', bgcolor: '#c4f1f9' } : { color: 'grey.800', bgcolor: '#E4E4E4' }),
                    // ...(isImage && { p: 0 }),
                    ...(isDocument&& { width:'300px',maxWidth:'300px' }),
                    ...(isMe
                      ? {
                          '::after': {
                            content: '""',
                            position: 'absolute',
                            width: '0%',
                            height: '0px',
                            left: 'auto',
                            right: '-8px',
                            top: '4px',
                            bottom: 'auto',
                            border: '10px solid',
                            borderColor: '#00000000',
                            borderTopColor: '#c4f1f9',
                            borderRadius: '4px',
                          },
                        }
                      : {
                          '::after': {
                            content: '""',
                            position: 'absolute',
                            width: '0%',
                            height: '0px',
                            left: '-10px',
                            right: 'auto',
                            top: '4px',
                            bottom: 'auto',
                            border: '10px solid',
                            borderColor: '#00000000',
                            borderTopColor: '#E4E4E4',
                            borderRadius: '6px',
                          },
                        }),
                  }}
                >
                  {renderMessageContent()}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', color: '#718096', gap:0.5 }}>
                    <Typography variant="body2" sx={{ fontSize: '10px' }}>
                     {isTime}
                    </Typography>
                    {isMe && ( messageStatus === 'read'? <DoneAllOutlinedIcon sx={{ width: '0.5em', height: '0.5em', color:'#239ED0' }} />:messageStatus === 'delivered'?<DoneAllOutlinedIcon sx={{ width: '0.5em', height: '0.5em' }} />:messageStatus === 'sent'?<DoneIcon sx={{ width: '0.5em', height: '0.5em' }}/>:messageStatus === 'failed'?<SmsFailedIcon sx={{ width: '0.5em', height: '0.5em', }}/>:<TimerSharpIcon sx={{ width: '0.5em', height: '0.5em' }}/>)}
                  </Box>
                </ContentStyle>
              </Box>
              {open && (
                <PopoverStyle
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <PopoverList>
                    <PopoverListItem>
                      <PopoverIcon>
                        <ReplyIcon sx={{ width: 18, height: 18 }} />
                      </PopoverIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Reply" />
                    </PopoverListItem>
                    <PopoverListItem>
                      <PopoverIcon>
                        <SentimentSatisfiedOutlinedIcon sx={{ width: 18, height: 18 }} />
                      </PopoverIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="React" />
                    </PopoverListItem>
                    <PopoverListItem>
                      <PopoverIcon>
                        <InfoOutlined sx={{ width: 18, height: 18 }} />
                      </PopoverIcon>
                      <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Info" />
                    </PopoverListItem>
                  </PopoverList>
                </PopoverStyle>
              )}
            </div>
          </Box>
        </RootStyle>
      )}
    </>
  );
}
