import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, IconButton, InputAdornment, List, Tab } from '@mui/material';
// routes
import { PATH_APP } from '../../../routes/paths';
// components
import { SkeletonConversationItem } from '../../../components/skeleton';
//
import ChatConversationItem from './ChatConversationItem';
import { setActiveConversation, getConversations } from 'src/redux/slices/chat';
import { useDispatch, useSelector } from '../../../redux/store';
import { getContacts, setConversationsSuccess, setContactsSuccess, clearContacts } from '../../../redux/slices/chat';
import { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Iconify from 'src/components/Iconify';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ApiEndpoints } from '../../../config';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CreateContactModal from './CreateContactModal';
import { formatTime } from './ChatMessageItem';

// ----------------------------------------------------------------------

ChatConversationList.propTypes = {
  conversations: PropTypes.array,
  isOpenSidebar: PropTypes.bool,
  activeConversationId: PropTypes.string,
  sx: PropTypes.object,
};
const CustomTabList = styled(TabList)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  '& .MuiTab-root': {
    flexShrink: 0,
    minWidth: '3.3vw',
    marginRight: '0 !important',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
      minWidth: 50,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '0 !important',
    },
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function ChatConversationList({
  svgIcons,
  isCollapse,
  isOpenSidebar,
  sx,
  conversations,
  activeConversationId,
  tabValue,
  setTabValue,
  ...other
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const userData = useSelector((state) => state?.auth?.user?.data);
  const contactsList = useSelector((state) => state.chat.contacts);
  const userRole = userData?.user?.user_type;
  const userId = userData?.user?.native_id;
  const organizationDepId =userData?.user?.organisation_department_id;
  const depId =userData?.user?.organization?.id;
  const handleSelectConversation = (contactDet) => {
    dispatch(getConversations(contactDet?.conversationId));
    dispatch(setActiveConversation(contactDet));
  };

  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleModalOpen = () => setContactModalOpen(true);
  const handleModalClose = () => setContactModalOpen(false);

  useEffect(() => {
    if (tabValue === '4') {
      dispatch(getContacts(userId, svgIcons[4].endpoint));
    }
  }, []);

  const loading = useSelector((state) => state.chat.isLoading);

  const onChange = (event) => {
    setSearch(event.target.value);
  };
  const filteredData = conversations?.filter(
    (user) =>
      user?.customerPhone?.toLowerCase()?.includes(search) ||
      user?.customerInfo?.customer_name?.toLowerCase()?.includes(search)
  );

  const handleChange = (event, newValue) => {
    const unassignedConversationId = `?organization_department_id=${organizationDepId}&organization_id=${depId}`;
    setTabValue(newValue);
    
    const selectedTab = svgIcons[newValue];

    dispatch(setActiveConversation(null));

    dispatch(clearContacts());

    if (selectedTab && selectedTab.endpoint) {
      if(newValue !== '0'){
        dispatch(getContacts(userId, selectedTab.endpoint));
      }else{
        dispatch(getContacts(unassignedConversationId, selectedTab.endpoint));
      }
    }
  };

  return (
    <>
      {!isCollapse && (
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1, gap: 1 }}>
          <TextField
            size="small"
            value={search}
            onChange={onChange}
            placeholder="Search contacts"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 15, height: 15 }} />
                </InputAdornment>
              ),
              inputProps: {
                style: { fontSize: '0.75rem' },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '100px',
                padding: '2px 4px',
                '& .MuiInputAdornment-root': {
                  marginRight: '2px',
                },
              },
              '& .MuiInputBase-input': {
                padding: '4px 0',
              },
              maxWidth: '200px',
            }}
          />
          <div>
            <BootstrapTooltip title="Create or select contact">
              <Button
                variant="outlined"
                sx={{ height: '25px' }}
                startIcon={<AddCircleIcon />}
                //  onClick={handleModalOpen}
              >
                New
              </Button>
            </BootstrapTooltip>
            <CreateContactModal open={contactModalOpen} handleClose={handleModalClose} />
          </div>
          <BootstrapTooltip title="Filters">
            <IconButton sx={{ p: 0 }}>
              <Iconify icon="mdi:filter-outline" width={20} height={20} />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Table view">
            <IconButton sx={{ p: 0 }}>
              <Iconify icon="mdi:view-grid-outline" width={20} height={20} />
            </IconButton>
          </BootstrapTooltip>
        </Box>
      )}
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <CustomTabList onChange={handleChange} sx={{ p: 0 }} aria-label="lab API tabs example">
            {svgIcons.map((item, index) => (
              <CustomTab key={index} icon={item.svgIcon} tooltipText={item.tooltipText} value={index.toString()} />
            ))}
          </CustomTabList>
        </Box>
        {svgIcons.map((item, index) => (
          <TabPanel key={index} value={index.toString()} sx={{ p: 0 }}>
            <List disablePadding sx={{ overflowY: 'auto', maxHeight: '400px' }}>
              {loading
                ? [...Array(12)].map((_, index) => <SkeletonConversationItem key={index} />)
                : filteredData &&
                  filteredData.map((contactDetail, index) =>
                    contactDetail?.customerPhone ? (
                      <ChatConversationItem
                        key={contactDetail?.conversationId}
                        isOpenSidebar={isOpenSidebar}
                        conversationStatus={contactDetail?.status}
                        conversation={contactDetail?.lastMessage?.message?.inComing}
                        conversationType={contactDetail?.lastMessage?.messageType}
                        converstaionTime={
                          contactDetail?.lastMessage?.messageType === 'INCOMING'
                            ? formatTime(contactDetail?.lastMessage?.deliveryTime)
                            : contactDetail?.lastMessage?.status === 'sent'
                            ? formatTime(contactDetail?.lastMessage?.sentTime)
                            : contactDetail?.lastMessage?.status === 'read'
                            ? formatTime(contactDetail?.lastMessage?.readTime)
                            : contactDetail?.lastMessage?.status === 'delivered'
                            ? formatTime(contactDetail?.lastMessage?.deliveryTime)
                            : contactDetail?.lastMessage?.status === 'failed'
                            ? formatTime(contactDetail?.lastMessage?.failedTime)
                            : '02 sep'
                        }
                        conversationInfo={contactDetail?.customerInfo}
                        conversationPhone={contactDetail?.customerPhone}
                        isSelected={activeConversationId?.conversationId === contactDetail?.conversationId}
                        onSelectConversation={() => contactDetail&&handleSelectConversation(contactDetail)}
                      />
                    ) : (
                      <SkeletonConversationItem key={index} />
                    )
                  )}
            </List>
            {!loading &&filteredData?.length === 0 && (
              <div
                style={{
                  padding: '20px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '400px',
                }}
              >
                No conversation to view
              </div>
            )}
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
}

const CustomTab = ({ icon, tooltipText, ...props }) => (
  <BootstrapTooltip title={tooltipText} placement="top">
    <Tab icon={icon} {...props} />
  </BootstrapTooltip>
);
