import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import {
  addRecipients,
  onSendMessage,
  getConversation,
  getParticipants,
  markConversationAsRead,
  resetActiveConversation,
} from '../../../redux/slices/chat';
// routes
import { PATH_APP } from '../../../routes/paths';
//
import ChatRoom from './ChatRoom';
import ChatMessageList from './ChatMessageList';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInput from './ChatMessageInput';
import ChatHeaderCompose from './ChatHeaderCompose';

// ----------------------------------------------------------------------
const getHoursRemaining = (deliveryTime) => {
  const oneDay = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const now = new Date();
  
  // Convert the deliveryTime string into a Date object
  const messageTime = new Date(deliveryTime.replace(' ', 'T')); // Replace space with 'T' for valid Date format
  
  // Calculate the time difference between now and the message delivery time
  const timeDifference = now - messageTime;

  // If timeDifference is less than one day (24 hours) and positive (valid past date), calculate hours remaining
  if (timeDifference < oneDay && timeDifference > 0) {
    const timeRemaining = oneDay - timeDifference;
    const hoursRemaining = Math.floor(timeRemaining / (60 * 60 * 1000)); // Convert milliseconds to hours
    return {
      isWithin24Hours: true,
      hoursRemaining,
    };
  }

  // If not within 24 hours, return false and 0 hours remaining
  return {
    isWithin24Hours: false,
    hoursRemaining: 0,
  };
};
const conversationSelector = (state) => {
  const { conversations, activeConversationId } = state.chat;
  const conversation = (activeConversationId && conversations.includes(activeConversationId)) ? activeConversationId : null;
  if (conversation) {
    return conversation;
  }
  const initState = {
    id: '',
    messages: [],
    participants: [],
    unreadCount: 0,
    type: '',
  };
  return initState;
};

export default function ChatWindow({sendMessage, templates, setTemplates}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { conversationKey } = useParams();
  const { contacts, recipients, participants, activeConversationId, conversations } = useSelector((state) => state.chat);
  const conversation = useSelector((state) => conversationSelector(state));
  const chat = useSelector((state) => state.chat.conversations)
  const mode = conversationKey ? 'DETAIL' : 'COMPOSE';
  const displayParticipants = participants.filter((item) => item.id !== '8864c717-587d-472a-929a-8e5f298024da-0');
  const [fileData, setFileData] = useState(null); 
  const [filePreview, setFilePreview] = useState(''); 
  const [uploadData, setUploadData] = useState(null);


  const incomingMessage = chat?.filter(chat => chat?.messageType === 'INCOMING')
  const lastIncomingmessage = incomingMessage?.[incomingMessage?.length-1]
  const lastMessageDeliveryTime = lastIncomingmessage?.deliveryTime;
  // Check if the current time is within 24 hours after the delivery time of the last message and get hours remaining
  const { isWithin24Hours, hoursRemaining } = lastMessageDeliveryTime
    ? getHoursRemaining(lastMessageDeliveryTime)
    : { isWithin24Hours: false, hoursRemaining: 0 };

  // useEffect(() => {
  //   if (activeConversationId) {
  //     dispatch(markConversationAsRead(activeConversationId));
  //   }
  // }, [dispatch, activeConversationId]);

  const handleAddRecipients = (recipients) => {
    dispatch(addRecipients(recipients));
  };

  const handleSendMessage = async (value) => {
    try {
      dispatch(onSendMessage(value));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Stack sx={{ flexGrow: 1, minWidth: '1px',width:'70vw' }}>
      {mode === 'DETAIL' ? (
        <ChatHeaderDetail participants={displayParticipants} />
      ) : (
        <ChatHeaderCompose
          recipients={recipients}
          contacts={chat}
          onAddRecipients={handleAddRecipients}
          activeConversationId={activeConversationId}
          hoursRemaining={hoursRemaining}
          templates={templates}
          setTemplates={setTemplates}
        />
      )}

      <Divider />

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack sx={{ flexGrow: 1 }}>
          <ChatMessageList conversation={chat} fileData={fileData} filePreview={filePreview} uploadData={uploadData}/>

          <Divider />

          {activeConversationId?.status && isWithin24Hours && <ChatMessageInput
            conversationId={activeConversationId}
            onSend={handleSendMessage}
            sendMessage={sendMessage}
            fileData={fileData}
            setFileData={setFileData}
            filePreview={filePreview}
            setFilePreview={setFilePreview}
            uploadData={uploadData}
            setUploadData={setUploadData}
            // disabled={pathname === PATH_APP.chat.new}
          />}
        </Stack>

        {/* {mode === 'DETAIL' && <ChatRoom conversation={conversation} participants={displayParticipants} />} */}
      </Box>
    </Stack>
  );
}
